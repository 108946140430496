import React from 'react';
import '../App.css';
import { Button } from './Button';
import './HeroSection.css';
import video from '../video/agro.mov'
import {useTranslation} from "react-i18next"

function HeroSection() {
  const {t} = useTranslation();
  return (
    <div className="hero-container">
      <video
        // src={"https://youtu.be/qB0N4pwrjE8?si=VCKFXLhyVL5WEAwR&t=19"}
        src={video}
        autoPlay
        loop
        muted
      />
      <h1>{t('Start today')}</h1>
      <p>{t('What are you waiting for?')}</p>
      <div className="hero-btns">
        <Button
          className="btns"
          buttonStyle="btn--outline"
          buttonSize="btn--large"
        >
         {t("GET STARTED")}
        </Button>
        <Button
          className="btns"
          buttonStyle="btn--primary"
          buttonSize="btn--large"
          onClick={console.log("hey")}
        >
          <i className="far fa-play-circle" />
        </Button>
      </div>
    </div>
  );
}

export default HeroSection;
