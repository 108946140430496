import React, { Suspense } from "react"; // Import Suspense from React
import ReactDOM from "react-dom";
import App from "./App";
import "./i18n"; // Ensure your i18n configuration is imported here

// Wrap your <App /> component with <Suspense> and provide a fallback UI
ReactDOM.render(
  <Suspense fallback={<div>Loading...</div>}>
    {" "}
    {/* This is your fallback UI */}
    <App />
  </Suspense>,
  document.getElementById("root")
);
