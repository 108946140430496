import React from "react";
import "./Cards.css";
import CardItem from "./CardItem";
import { useTranslation } from "react-i18next";

function Cards() {
   const { t, i18n } = useTranslation();
   const changeLanguage = (event) => {
     i18n.changeLanguage(event.target.value);
   };


  return (
    <div className="cards">
      <h1>{t("Explore AgroDrone")}</h1>
      <div className="cards__container">
        <div className="cards__wrapper">
          <ul className="cards__items">
            <CardItem
              src="images/1.png"
              text={t(
                "The AGRAS T40 is equipped with the revolutionary Coaxial Twin Rotor design, enabling it to carry a spray load of 40 kg[1] and a spread load of 50 kg (70 L)"
              )}
              label={t("Adventure")}
              path="/"
            />

            <CardItem
              src="images/2.png"
              text={t("per hour 21.3 hectare(farmland)")}
              label={t("Farmland")}
              path="/"
            />
          </ul>
          <ul className="cards__items">
            <CardItem
              src="images/3.png"
              text={t("per hour 4 hectares")}
              label={t("orchards")}
              path="/"
            />

            <CardItem
              src="images/4.png"
              text={t("per hour 1.5 tonnes of fertilizer")}
              label={t("spreading")}
              path="/"
            />
            <CardItem
              src="images/5.png"
              text={t(
                "The system detects land boundaries and obstacles automatically, for quicker planning of flight routes over farmland and hilly orchards."
              )}
              label={t("maping")}
              path="/"
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
