// import i18n from "i18next";
// import { initReactI18next } from "react-i18next";
// import HttpBackend from "i18next-http-backend";
// import LanguageDetector from "i18next-browser-languagedetector";

// i18n
//   .use(HttpBackend) // Loads translations from the server
//   .use(LanguageDetector) // Detects user language
//   .use(initReactI18next) // Passes i18n down to react-i18next
//   .init({
//     fallbackLng: "ka", // Use 'en' if detected language is not available
//     debug: true, // Set to false in production
//     interpolation: {
//       escapeValue: false, // React already safes from XSS
//     },
//     backend: {
  
//       // Assuming your translations are in public/locales/<language_code>/translation.json
//       loadPath: "/locales/{{lng}}/translation.json",
//     },
//     supportedLngs: ["ka", "en"],
//   });

// export default i18n;
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(HttpBackend) // Loads translations from the server
  .use(LanguageDetector) // Detects user language
  .use(initReactI18next) // Passes i18n down to react-i18next
  .init(
    {
      fallbackLng: "ka", // Use 'ka' if detected language is not available
      debug: true, // Set to false in production
      interpolation: {
        escapeValue: false, // React already safes from XSS
      },
      backend: {
        // Assuming your translations are in public/locales/<language_code>/translation.json
        loadPath: "/locales/{{lng}}/translation.json",
      },
      supportedLngs: ["ka", "en"],
    },
    (err, t) => {
      if (err) return console.error(err);
      // Explicitly set Georgian as the initial language
      i18n.changeLanguage("ka");
    }
  );

export default i18n;
