import React, { useState, useEffect } from 'react';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import './Navbar.css';
import { useTranslation } from "react-i18next";

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

 const {t, i18n } = useTranslation();
  const changeLanguage = (event) => {
      i18n.changeLanguage(event.target.value);
    };

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener('resize', showButton);

  return (
    <>
      <nav className="navbar">
        {/* Language Switcher */}
        <div style={{ textAlign: "right", padding: "10px" }}>
          <select onChange={changeLanguage}>
            <option value="ka">Georgian</option>
            <option value="en">English</option>
          </select>
        </div>
        <div className="navbar-container">
          <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
            AGROFLY
          </Link>
          <div className="menu-icon" onClick={handleClick}>
            <i className={click ? "fas fa-times" : "fas fa-bars"} />
          </div>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <Link to="/" className="nav-links" onClick={closeMobileMenu}>
                {t('Home')}
              </Link>
            </li>

            <li className="nav-item">
              <Link
                to="/sign-up"
                className="nav-links-mobile"
                onClick={closeMobileMenu}
              >
                {t('Booking')}
              </Link>
            </li>
          </ul>
          {button && <Button buttonStyle="btn--outline">{t("Booking")}</Button>}
        </div>
      </nav>
    </>
  );
}

export default Navbar;
