import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import usaidLogoEnglish from "../usaidlogo/usa1.png"; 
import usaidLogoGeorgian from "../usaidlogo/usa2.png"; 

function Footer() {
  const { t, i18n } = useTranslation();

  // Determine which logo to display based on the current language
  const usaidLogo =
    i18n.language === "ka" ? usaidLogoGeorgian : usaidLogoEnglish;

  return (
    <div className="footer-container">
      <section className="footer-usaid">
        <div className="usaid-logo-wrap">
          <img src={usaidLogo} alt="USAID Logo" />
        </div>
        <div className="usaid-content">
          <p>
            {t(
              "This website was made possible with support from the American people, funded through the U.S. Agency for International Development (USAID) and delivered by the USAID Resilient Communities Program. The contents of the website do not reflect the opinion of USAID or the U.S. Government."
            )}
          </p>
        </div>
      </section>
      <div className="footer-links"></div>
      <section className="social-media">
        <div className="social-media-wrap">
          {/* ... your existing social media links ... */}
          <small className="website-rights">
            AGROFLY © {new Date().getFullYear()}
          </small>
        </div>
      </section>
    </div>
  );
}

export default Footer;
